// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import './CheckBox.scss';

function CustomCheckBox(props) {
  const classNameValue =
    props.labelClassName +
    (props.isChecked?.toString() === 'true' ? ' lg-active' : '');
  return (
    <div
      className={`checkbox custom-checkbox ${props.class}`}
      data-testid="lg-custom-checkbox"
    >
      <input
        checked={props.isChecked?.toString() !== 'false'}
        className="lg-hidden-checkbox"
        data-cy-filter-feature={props.filterFeature}
        id={props.id}
        onChange={props.onChange}
        type="checkbox"
        value={props.value}
      />
      <label
        className={`lg-custom-checkbox ${classNameValue}`}
        htmlFor={props.id}
      >
        {props.name}
      </label>
    </div>
  );
}

CustomCheckBox.propTypes = {
  class: PropTypes.string,
  filterFeature: PropTypes.string,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default CustomCheckBox;
