// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import './MetroCheckbox.scss';

function MetroCheckbox({ name, handleClick, isMetroChecked, isPartial }) {
  let classNameValue = '';
  if (isMetroChecked) {
    classNameValue = 'campus-active';
  } else if (isPartial) {
    classNameValue = 'partial-check';
  }
  return (
    <div
      className="metro-checkbox"
      data-testid="lg-metro-checkbox"
      onClick={handleClick}
    >
      <label className={`lg-location-checkbox`} htmlFor={name}>
        {name}
      </label>
      <span className={`checkbox ${classNameValue}`} />
    </div>
  );
}

MetroCheckbox.propTypes = {
  handleClick: PropTypes.func,
  isMetroChecked: PropTypes.bool,
  isPartial: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

MetroCheckbox.defaultProps = {
  isMetroChecked: false,
  isPartial: false,
};

export default MetroCheckbox;
