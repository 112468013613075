/* eslint-disable class-methods-use-this */
import _ from 'lodash';
import 'isomorphic-fetch';

class Api {
  store(store) {
    this.store = store;
  }

  getStore() {
    return this.store;
  }

  get(url, params) {
    return this.request(url, 'GET', null, params);
  }

  put(url, body, params) {
    return this.request(url, 'PUT', body, params);
  }

  request(url, method, body, params = {}) {
    const promise = new Promise((accept, reject) => {
      fetch(`${url}${this.params(params)}`, {
        body,
        headers: {
          'Content-Type': 'application/json',
        },
        method,
        mode: 'cors',
      })
        .then((resp) => this.checkValidResponse(resp, reject))
        .then((resp) => accept(resp))
        .catch((err) => {
          throw err;
        });
    });
    promise.catch((err) => {
      throw err;
    });
    return promise;
  }

  params(params = {}) {
    let stringParams = '';
    let index = 0;
    const { length } = Object.keys(params);
    _.forOwn(params, (value, key) => {
      stringParams += value ? [key, encodeURIComponent(value)].join('=') : '';
      stringParams += length > 1 && index < length - 1 && value ? '&' : '';
      index += 1;
    });
    return stringParams ? `?${stringParams}` : '';
  }

  checkValidResponse(response, reject) {
    if (response.ok) {
      return response.json();
    }
    reject(response);
    return null;
  }

  handleError(err) {
    throw err;
  }

  headers() {
    return new Headers({
      'Content-Type': 'application/json',
    });
  }
}

const API = new Api();
export default API;
