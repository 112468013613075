// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import GroupTypes from '../GroupTypes';
import CategoriesCheckboxes from '../CategoriesCheckboxes';
import DayOfWeekCheckboxes from '../DayOfWeekCheckboxes';
import LocationsContainer from '../LocationsContainer';
import CountryContainer from '../CountryContainer';
import './SearchOptions.scss';

function SearchOptions(props) {
  return (
    <div className="lg-search-options" data-testid="lg-search-options">
      <div className="row column small-12 small-centered lg-search-container">
        <div className="lg-filter-header-container">
          <h2 className="lg-header">
            Find a LifeGroup or Local Mission Partner
          </h2>

          <div className="lg-search-filters">
            <LocationsContainer id="lg-campus-code" />

            {props?.filteredList?.includes('int') ? (
              <CountryContainer
                filterFeature="country"
                id="lg-campus-code"
                mobileLabel="Country"
                options={props.churchOnlineLocations}
                value={props.churchOnlineSlug}
              />
            ) : null}

            <GroupTypes
              classToggle={props.isGroupTypeCheckboxToggled}
              groupTypes={props.groupTypes}
              onChange={props.onGroupTypeChecked}
              onClick={props.onGroupTypeCheckboxClicked}
              toggleCheckboxGroup={props.toggleCheckboxGroup}
              toggleCheckboxGroupArrow={props.toggleCheckboxGroupArrow}
              toggleCheckboxGroupColor={props.toggleCheckboxGroupColor}
            />
            {props.groupTypes !== 'local-partner' && (
              <DayOfWeekCheckboxes
                classToggle={props.isDayCheckboxGroupToggled}
                days={props.days}
                onBlur={props.onDayCheckboxGroupBlurred}
                onChange={props.onDayChecked}
                onClick={props.onDayCheckboxGroupClicked}
                toggleCheckboxGroup={props.toggleCheckboxGroup}
                toggleCheckboxGroupArrow={props.toggleCheckboxGroupArrow}
                toggleCheckboxGroupColor={props.toggleCheckboxGroupColor}
              />
            )}
            <CategoriesCheckboxes
              age={props.age}
              categories={props.categories}
              classGroupTypeToggle={props.isGroupTypeCheckboxToggled}
              classToggle={props.isCategoryCheckboxGroupToggled}
              groupTypes={props.groupTypes}
              kidsWelcome={props.kidsWelcome}
              meetOnline={props.meetOnline}
              moreFiltersContainerRef={props.moreFiltersContainerRef}
              onAgeChanged={props.onAgeChanged}
              onChange={props.onCategoryChecked}
              onClick={props.onCategoryCheckboxGroupClicked}
              onGroupTypeChange={props.onGroupTypeChecked}
              onGroupTypeCheckboxClicked={props.onGroupTypeCheckboxClicked}
              onKidsWelcomeChange={props.onKidsWelcomeChange}
              onMeetOnlineChange={props.onMeetOnlineChange}
              screenSize="desktop"
              toggleCheckboxGroup={props.toggleCheckboxGroup}
              toggleCheckboxGroupArrow={props.toggleCheckboxGroupArrow}
              toggleCheckboxGroupColor={props.toggleCheckboxGroupColor}
            />
            <div className="lg-input-container">
              <div className="lg-inputs">
                <input
                  className="lg-form-age m-r-1"
                  data-cy-age="mobile"
                  maxLength="3"
                  onChange={props.onAgeChanged}
                  pattern="\d*"
                  placeholder="Age"
                  type="text"
                  value={props.age}
                />
                <input
                  className="lg-form-keyword"
                  data-cy-search-keywords=""
                  id="lg-keyword"
                  onChange={props.onKeywordChanged}
                  placeholder="Search Keywords"
                  type="text"
                  value={props.keywords}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SearchOptions.propTypes = {
  age: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  churchOnlineSlug: PropTypes.string.isRequired,
  days: PropTypes.array.isRequired,
  filteredList: PropTypes.array.isRequired,
  isCategoryCheckboxGroupToggled: PropTypes.bool.isRequired,
  isDayCheckboxGroupToggled: PropTypes.bool.isRequired,
  keywords: PropTypes.string.isRequired,
  kidsWelcome: PropTypes.string,
  meetOnline: PropTypes.string,
  moreFiltersContainerRef: PropTypes.func,
  onAgeChanged: PropTypes.func.isRequired,
  onCategoryCheckboxGroupClicked: PropTypes.func.isRequired,
  onCategoryChecked: PropTypes.func.isRequired,
  onDayCheckboxGroupBlurred: PropTypes.func.isRequired,
  onDayCheckboxGroupClicked: PropTypes.func.isRequired,
  onDayChecked: PropTypes.func.isRequired,
  onKeywordChanged: PropTypes.func.isRequired,
  onKidsWelcomeChange: PropTypes.func.isRequired,
  onMeetOnlineChange: PropTypes.func.isRequired,
  toggleCheckboxGroup: PropTypes.func.isRequired,
  toggleCheckboxGroupArrow: PropTypes.func.isRequired,
  toggleCheckboxGroupColor: PropTypes.func.isRequired,
};

export default SearchOptions;
