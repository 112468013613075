// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import './SearchFilter.scss';

function SearchFilter({ searchText, handleSearch }) {
  return (
    <div className="searchbar" data-testid="lg-search-filter">
      <input
        onChange={(event) => handleSearch(event)}
        onKeyPress={(event) => {
          return /[a-z]/i.test(event.key);
        }}
        placeholder="Search"
        type="text"
        value={searchText}
      />
    </div>
  );
}

SearchFilter.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default SearchFilter;
