/* eslint-disable class-methods-use-this */
// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import loading from '../../images/lifegroups-loading.svg';

import './Loading.scss';

class Loading extends Component {
  render() {
    const style = {
      display: 'flex',
      justifyContent: 'center',
    };
    const imgStyle = {
      height: 'auto',
      width: '100px',
    };

    return (
      <div className="loading" data-testid="lg-loading" style={style}>
        <img
          alt="loading"
          data-no-retina={true}
          src={loading}
          style={imgStyle}
        />
      </div>
    );
  }
}

export default Loading;
