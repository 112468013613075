// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from 'react-scroll-up';
import MediaQuery from 'react-responsive';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, APP_NAME, EVENTS } from '../../types';
import SearchContainer from '../SearchContainer';
import ListContainer from '../ListContainer';
import ScrollToTopImage from '../../images/scroll_to_top.svg';

function SearchPage(props) {
  /**
   * Style object is optional, overridden here with values to ensure visibility
   * and more proper placement.
   *
   * @see {@link https://www.npmjs.com/package/react-scroll-up}.
   */
  const scrollToTopStyle = {
    bottom: 90,
    cursor: 'pointer',
    position: 'fixed',
    right: 20,
    transitionDelay: '0s',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'linear',
  };

  return (
    <div className="search-page" data-testid="lg-search-page">
      <SearchContainer {...props} />
      <ListContainer {...props} />
      <MediaQuery query="(min-device-width: 768px)">
        <div
          className="lg-scroll-to-top"
          onClick={() => {
            const user = getUser();
            callSegmentTrack({
              event: EVENTS.buttonAction,
              properties: {
                action: ACTIONS.clicked,
                category: APP_NAME,
                component: 'Search Page',
                component_url: null,
                label: 'Scroll to Top',
                logged_in: !!user,
                preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                referrer: document?.referrer || null,
                title: document?.title || '',
                url: window?.location?.href,
                user_id: user?.['https://www.life.church/rock_person_alias_id'],
              },
            });
          }}
        >
          <ScrollToTop showUnder={200} style={scrollToTopStyle}>
            <img
              alt="Scroll to top"
              data-no-retina={true}
              src={ScrollToTopImage}
            />
          </ScrollToTop>
        </div>
      </MediaQuery>
    </div>
  );
}

SearchPage.propTypes = {
  user: PropTypes.object,
};

export default SearchPage;
