/* eslint-disable no-shadow */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CampusCheckbox from '../CampusCheckbox';
import MetroCheckbox from '../MetroCheckbox';
import { fetchLifegroupData, removeCampus, setCampus } from '../../actions';
import './FilteredMetroGroup.scss';

function FilteredMetroGroup({
  campusList,
  fetchLifegroupData,
  filteredList,
  isAllChecked,
  name,
  removeCampus,
  setCampus,
  setIsAllChecked,
}) {
  const [isMetroChecked, setIsMetroChecked] = React.useState(true);
  const [isPartial, setIsPartial] = React.useState(false);
  const campusesInMetro = campusList.filter((campus) => {
    return campus.metro === name;
  });
  const slugsInMetro = campusesInMetro.map((cam) => {
    return cam.slug;
  });
  const containsAll = slugsInMetro.every((slug) => {
    return filteredList?.includes(slug);
  });
  const containsSome = slugsInMetro.some((slug) => {
    return filteredList?.includes(slug);
  });

  function handleHiddenMetroClick() {
    if (!isAllChecked) {
      // If only some locations are checked, then...
      if (isMetroChecked) {
        // If fully checked, uncheck everything in group
        setIsMetroChecked(false);
        setIsPartial(false);
        slugsInMetro.forEach((slug) => {
          removeCampus(slug);
        });
      } else {
        // If partially checked or unchecked, check everything in group.
        setIsMetroChecked(true);
        setIsPartial(false);
        slugsInMetro.forEach((slug) => {
          if (!filteredList?.includes(slug)) {
            setCampus(slug);
          }
        });
      }
    } else {
      // If all locations are checked, then...
      removeCampus('all');
      slugsInMetro.forEach((slug) => {
        if (!filteredList?.includes(slug)) {
          setCampus(slug);
        }
      });
      setIsAllChecked(false);
      setIsPartial(false);
      setIsMetroChecked(true);
    }
    fetchLifegroupData();
  }

  React.useEffect(() => {
    if (containsAll) {
      setIsMetroChecked(true);
    } else if (containsSome) {
      setIsMetroChecked(false);
      setIsPartial(true);
    } else {
      setIsMetroChecked(false);
      setIsPartial(false);
    }
  }, [containsAll, containsSome]);

  React.useEffect(() => {
    if (!isAllChecked && !filteredList.length) {
      setIsPartial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllChecked]);

  return (
    <div
      className="filtered-metro metro-group"
      data-testid="lg-filtered-metro-group"
    >
      <MetroCheckbox
        handleClick={handleHiddenMetroClick}
        isMetroChecked={isMetroChecked || isAllChecked}
        isPartial={isPartial}
        key={name}
        name={name}
      />
      {campusesInMetro
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
        .map((campus) => (
          <CampusCheckbox
            isAllChecked={isAllChecked}
            isMetroChecked={isMetroChecked}
            key={campus.slug}
            name={campus.name}
            setIsAllChecked={setIsAllChecked}
            setIsMetroChecked={setIsMetroChecked}
            setIsPartial={setIsPartial}
            slug={campus.slug}
          />
        ))}
    </div>
  );
}

function mapStateToProps({ core }) {
  return {
    campusList: core.campusList,
    filteredList: core.filteredList,
  };
}

FilteredMetroGroup.propTypes = {
  isAllChecked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  setIsAllChecked: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchLifegroupData,
  removeCampus,
  setCampus,
})(FilteredMetroGroup);
