import ReactGA4 from 'react-ga4';
import { APP_CONFIG } from '../variables';

const ga4MeasurementID = APP_CONFIG.ga4MeasurementId;

const configurationOptionsGA4 = {
  testMode: false,
};

// GA4.
ReactGA4.initialize(ga4MeasurementID, configurationOptionsGA4);

export { ReactGA4 };
