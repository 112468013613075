// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import Placeholder from '../../images/placeholder-card.svg';
import Loading from '../../images/loading-icon.svg';

export default function PlaceholderCard(props) {
  return (
    <div
      className="row small-up-1 medium-up-2 large-up-3 text-center"
      data-testid="lg-placeholder-cards"
    >
      <div className={`columns column-block m-b-1 ${props.className}`}>
        <img alt="Placeholder card" data-no-retina={true} src={Placeholder} />
      </div>
      <div className={`columns column-block m-b-1 ${props.className}`}>
        <img alt="Placeholder card" data-no-retina={true} src={Placeholder} />
      </div>
      <div className={`columns column-block m-b-1 ${props.className}`}>
        <img alt="Placeholder card" data-no-retina={true} src={Placeholder} />
      </div>
      <div className="loading">
        <img
          alt="Placeholder card"
          className="inline-block"
          data-no-retina={true}
          src={Loading}
        />
        <span>Loading Groups</span>
      </div>
    </div>
  );
}

PlaceholderCard.defaultProps = {
  className: '',
};

PlaceholderCard.propTypes = {
  className: PropTypes.string,
};
