/* eslint-disable default-param-last */
/**
 * @module AnalyticsReducer
 */
import { callSegmentTrack, getDayOfWeekLabel } from '../helper';
import { ReactGA4 } from '../analytics';

import {
  EVENT_NAME_AGE_CLICKED,
  EVENT_NAME_AGE_SELECTED,
  EVENT_NAME_CAMPUS_CLICKED,
  EVENT_NAME_CAMPUS_SELECTED,
  EVENT_NAME_CATEGORY_CLICKED,
  EVENT_NAME_CATEGORY_SELECTED,
  EVENT_NAME_DAY_CLICKED,
  EVENT_NAME_DAY_SELECTED,
  EVENT_NAME_GROUP_TYPE_CLICKED,
  EVENT_NAME_GROUP_TYPE_SELECTED,
  EVENT_NAME_KEYWORD_TYPED,
  EVENT_NAME_KIDS_WELCOME,
  EVENT_NAME_MEET_ONLINE,
  EVENT_NAME_SEARCH_TRAY_CLICKED,
  EVENT_NAME_SEARCH_TRAY_SELECTED,
  REMOVE_CAMPUS,
  SET_AGE,
  SET_CAMPUS,
  SET_CATEGORY,
  SET_DAY,
  SET_GROUP_TYPE,
  SET_KEYWORDS,
  SET_KIDS_WELCOME,
  SET_MEET_ONLINE,
  TOGGLE_SEARCH_TRAY,
} from '../types';

export default (state = {}, action) => {
  const status = action.payload ? 'Opened' : 'Closed';

  /**
   * Convenience function to convert two-character day labels to three-character equivalent.
   *
   * @param {Array} days - Array of two-character day labels.
   *
   * @returns {Array} Array of three-character day labels.
   */
  function convertDays(days) {
    return days.map((day) => {
      return getDayOfWeekLabel(day);
    });
  }

  switch (action.type) {
    case SET_CATEGORY:
      callSegmentTrack({
        event: EVENT_NAME_CATEGORY_SELECTED,
        properties: {
          category: 'LifeGroups Search',
          value: `${action.payload}`,
        },
      });
      ReactGA4.event({
        action: 'User Interaction',
        category: 'LifeGroups Search',
        label: `${EVENT_NAME_CATEGORY_CLICKED} - ${action.payload.slice(-1)}`,
      });
      break;
    case SET_DAY:
      callSegmentTrack({
        event: EVENT_NAME_DAY_SELECTED,
        properties: {
          category: 'LifeGroups Search',
          value: `${convertDays(action.payload)}`,
        },
      });
      ReactGA4.event({
        action: 'User Interaction',
        category: 'LifeGroups Search',
        label: `${EVENT_NAME_DAY_CLICKED} - ${action.payload.slice(-1)}`,
      });
      break;
    case SET_GROUP_TYPE:
      callSegmentTrack({
        event: EVENT_NAME_GROUP_TYPE_SELECTED,
        properties: {
          category: 'LifeGroups Search',
          value: `${action.payload}`,
        },
      });
      ReactGA4.event({
        action: 'User Interaction',
        category: 'LifeGroups Search',
        label: `${EVENT_NAME_GROUP_TYPE_CLICKED} - ${action.payload}`,
      });
      break;
    case SET_AGE:
      if (action.payload.length >= 2) {
        callSegmentTrack({
          event: EVENT_NAME_AGE_SELECTED,
          properties: {
            category: 'LifeGroups Search',
            value: `${action.payload}`,
          },
        });
        ReactGA4.event({
          action: 'User Interaction',
          category: 'LifeGroups Search',
          label: `${EVENT_NAME_AGE_CLICKED} - ${action.payload}`,
        });
      }
      break;
    case SET_CAMPUS:
    case REMOVE_CAMPUS:
      if (action.sendAnalytics && action.payload) {
        callSegmentTrack({
          event: EVENT_NAME_CAMPUS_SELECTED,
          properties: {
            category: 'LifeGroups Search',
            value: `${action.payload.length ? action.payload : 'None'}`,
          },
        });
        ReactGA4.event({
          action: 'User Interaction',
          category: 'LifeGroups Search',
          label: `${EVENT_NAME_CAMPUS_CLICKED} - ${
            action.payload.length ? action.payload : 'None'
          }`,
        });
      }
      break;
    case SET_KIDS_WELCOME:
      if (action.payload.length >= 2) {
        callSegmentTrack({
          event: EVENT_NAME_KIDS_WELCOME,
          properties: {
            category: 'LifeGroups Search',
            value: `${action.payload}`,
          },
        });
        ReactGA4.event({
          action: 'User Interaction',
          category: 'LifeGroups Search',
          label: `${EVENT_NAME_KIDS_WELCOME} - ${action.payload}`,
        });
      }
      break;
    case SET_MEET_ONLINE:
      if (action.payload.length >= 2) {
        callSegmentTrack({
          event: EVENT_NAME_MEET_ONLINE,
          properties: {
            category: 'LifeGroups Search',
            value: `${action.payload}`,
          },
        });
        ReactGA4.event({
          action: 'User Interaction',
          category: 'LifeGroups Search',
          label: `${EVENT_NAME_MEET_ONLINE} - ${action.payload}`,
        });
      }
      break;
    case TOGGLE_SEARCH_TRAY:
      callSegmentTrack({
        event: EVENT_NAME_SEARCH_TRAY_SELECTED,
        properties: {
          category: 'LifeGroups Search',
          value: `${status}`,
        },
      });
      ReactGA4.event({
        action: 'User Interaction',
        category: 'LifeGroups Search',
        label: `${EVENT_NAME_SEARCH_TRAY_CLICKED} - ${status}`,
      });
      break;
    case SET_KEYWORDS:
      if (action.payload.length >= 3) {
        callSegmentTrack({
          event: EVENT_NAME_KEYWORD_TYPED,
          properties: {
            category: 'LifeGroups Search',
            value: `${action.payload}`,
          },
        });
        ReactGA4.event({
          action: 'User Interaction',
          category: 'LifeGroups Search',
          label: `${EVENT_NAME_KEYWORD_TYPED} - ${action.payload}`,
        });
      }
      break;
    default:
      break;
  }
  return state;
};
