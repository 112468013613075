// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import { fetchMoreData } from '../../actions';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, APP_NAME, DAYS_OF_WEEK, EVENTS } from '../../types';
import { APP_CONFIG } from '../../variables';
import LgCard from '../LgCard';
import PlaceholderCards from '../PlaceholderCards';
import Loading from '../Loading';
import './ListContainer.scss';

const lifegroupLinkUrl = `https://www.life.church/webview-app/multistep-forms/serving/?utm_source=web&utm_medium=lifegroup-search-app&opportunity=${APP_CONFIG.servingOpportunityGuid}`;
const churchonlineLinkUrl =
  'https://lp.life.church/onlinelifegroup/?utm_medium=referral&utm_source=life_church&utm_campaign=co_life_group&utm_content=co_start_life_group';

function setAgeLimits(minAge, maxAge) {
  let maxStr;
  let minStr = minAge.toString();
  if (minAge < 18) {
    minStr = '18';
  }
  if (maxAge >= 99) {
    maxStr = ' & up';
  } else {
    maxStr = ` -  ${maxAge}`;
  }
  return `${minStr} ${maxStr}`;
}

class ListContainer extends Component {
  render() {
    // eslint-disable-next-line no-shadow
    const { dataList, fetchMoreData, hasMoreItems, links } = this.props;

    /* istanbul ignore next*/
    const loadMore = () => {
      fetchMoreData(links.next);
    };

    const renderStartAGroup = () => {
      if (!hasMoreItems) {
        const { meta, campus } = this.props;
        const url =
          campus?.slug === 'int' ? churchonlineLinkUrl : lifegroupLinkUrl;
        return (
          <div
            className={`start-a-group row ${
              meta['record-count'] > 0 && 'p-t-3'
            }`}
          >
            <div className="columns small-12 medium-6 medium-centered">
              <h1 className="title">
                Didn&rsquo;t find what you were looking for?
              </h1>
              <p className="subtitle">
                Try broadening your search, or consider
                <a
                  href={implementUtmParams(url, window?.location)}
                  onClick={(event) => {
                    const user = getUser();
                    callSegmentTrack({
                      event: EVENTS.buttonAction,
                      properties: {
                        action: ACTIONS.clicked,
                        category: APP_NAME,
                        component: 'Search Results',
                        component_url:
                          event?.currentTarget?.getAttribute('href'),
                        label: event?.currentTarget?.textContent,
                        location: 'Search Results',
                        logged_in: !!user,
                        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                        referrer: document?.referrer || null,
                        title: document?.title || '',
                        url: window?.location?.href,
                        user_id:
                          user?.[
                            'https://www.life.church/rock_person_alias_id'
                          ],
                      },
                    });
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {` starting a LifeGroup.`}
                </a>
              </p>
            </div>
          </div>
        );
      }
      return null;
    };

    const renderSwitchText = () => {
      return (
        <div className="row">
          <div className="columns small-12 medium-6 medium-centered text-center">
            <h1 className="title">Are you in high school or middle school?</h1>
            <p className="subtitle">
              <a
                href="//life.church/switch/"
                onClick={(event) => {
                  const user = getUser();
                  callSegmentTrack({
                    event: EVENTS.buttonAction,
                    properties: {
                      action: ACTIONS.clicked,
                      category: APP_NAME,
                      component: 'Search Results',
                      component_url: event?.currentTarget?.getAttribute('href'),
                      label: event?.currentTarget?.textContent,
                      location: 'Search Results',
                      logged_in: !!user,
                      preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
                      referrer: document?.referrer || null,
                      title: document?.title || '',
                      url: window?.location?.href,
                      user_id:
                        user?.['https://www.life.church/rock_person_alias_id'],
                    },
                  });
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more about our small groups at Switch!
              </a>
            </p>
          </div>
        </div>
      );
    };

    const renderNoResultsText = () => {
      const { age } = this.props;
      if (age && age < 18) {
        return renderSwitchText();
      }
      return renderStartAGroup();
    };

    const getDayLabel = (value) => {
      const day = DAYS_OF_WEEK.find((o) => o.value === value);
      return day !== undefined ? day.name : value;
    };

    //  change this depending on what the data looks like
    // const getGroupType = value => {
    //   const groupType = DAYS_OF_WEEK.find(o => o.value === value)
    //   return groupType !== undefined ? groupType.name : value;
    // }

    const renderList = dataList.map((list) => (
      <LgCard
        key={list.id}
        lgAgeRange={setAgeLimits(
          list.attributes.agemin,
          list.attributes.agemax,
        )}
        lgCampusCode={list.attributes['campus-code']}
        lgCampusLocation={list.attributes['campus-location']}
        lgCampusName={list.attributes['campus-name']}
        lgCategory={list.attributes['community-types'][0]}
        lgDay={getDayLabel(list.attributes['day-of-week'])}
        lgDescription={list.attributes.description}
        lgFrequency={list.attributes['meeting-frequency']}
        // lgGroupType={getGroupType(list.attributes["group-type"])} // change depending on what the data looks like
        lgId={list.attributes.guid}
        lgKidsWelcome={list.attributes['kids-welcome']}
        lgLocation={list.attributes['lg-loc']}
        lgMeetOnline={list.attributes['meet-online']}
        lgName={list.attributes.name}
        lgTime={list.attributes['meeting-time']}
        lgTimeZone={list.attributes['utc-time-zone']}
        user={this.props.user}
      />
    ));

    if (this.props.isFetching) {
      return <Loading />;
    }

    return (
      <div className="list-container">
        <div className="row">
          <div className="column small-12 hidden-md">
            <p className="lg-found-text font-weight-semibold p-l-half">
              {this.props.meta['record-count']}{' '}
              {parseInt(this.props.meta['record-count'], 10) === 1
                ? 'Result'
                : 'Results'}{' '}
              Found
            </p>
          </div>
        </div>
        <InfiniteScroll
          hasMore={hasMoreItems}
          loadMore={loadMore}
          loader={<PlaceholderCards key={0} />}
          pageStart={0}
        >
          <div className="row small-up-1 medium-up-2 large-up-3">
            {renderList}
          </div>
        </InfiniteScroll>
        {renderNoResultsText()}
      </div>
    );
  }
}

function mapStateToProps({ core }) {
  const { data, links, hasMoreItems, meta } = core.lifegroupsList;
  return {
    age: core.age,
    campus: core.campus,
    dataList: data,
    hasMoreItems,
    isFetching: core.isFetching,
    links,
    meta,
    user: core.user,
  };
}

ListContainer.propTypes = {
  age: PropTypes.string,
  campus: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  dataList: PropTypes.array,
  fetchMoreData: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  isFetching: PropTypes.bool,
  links: PropTypes.object,
  meta: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps, { fetchMoreData })(ListContainer);
