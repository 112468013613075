// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../CheckBox';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, EVENTS, GROUP_TYPES } from '../../types';
import './GroupTypes.scss';

function GroupTypes(props) {
  const renderCheckboxes = () => {
    const isChecked = (value) => {
      return props?.groupTypes?.includes(value);
    };
    return GROUP_TYPES.map((groupType) => (
      <CheckBox
        cypress={`mobile-${groupType.value}`}
        id={groupType.value.toLowerCase()}
        isChecked={isChecked(groupType.value)}
        key={groupType.value}
        labelClassName="lg-group-type-checkbox"
        name={groupType.name}
        onChange={props.onChange}
        value={groupType.value}
      />
    ));
  };

  /**
   * Handler function for dropdown click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleDropdownClick(event) {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Group Types Container',
        component_url: null,
        label: props.classToggle ? ACTIONS.expanded : ACTIONS.collapsed,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    props.onClick(event);
  }

  return (
    <div
      className="lg-checkbox-group-type-container"
      data-testid="lg-group-types"
      onBlur={props.onBlur}
      onClick={handleDropdownClick}
      tabIndex={-1}
    >
      <p className="group-view m-b-half font-weight-bold color-LC-black">
        Group Types
      </p>
      <div className={`${props.toggleCheckboxGroup(props.classToggle)}`}>
        <div className="group-type-container">{renderCheckboxes()}</div>
      </div>
    </div>
  );
}
GroupTypes.propTypes = {
  classToggle: PropTypes.bool.isRequired,
  groupTypes: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  toggleCheckboxGroup: PropTypes.func.isRequired,
};
export default GroupTypes;
