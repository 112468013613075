// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import CampusCheckbox from '../CampusCheckbox';
import MetroGroup from '../MetroGroup';
import './CampusCheckboxContainer.scss';

function CampusCheckboxContainer({
  campuses,
  state,
  isAllChecked,
  setIsAllChecked,
}) {
  return (
    <div
      className="checkbox location-checkbox"
      data-testid="lg-campus-checkbox-container"
      key={state}
    >
      <label className="lg-location-checkbox state-text" htmlFor={state}>
        {state}
      </label>
      {Object.entries(campuses).map((locations) => {
        if (locations[0] === 'none') {
          return locations[1].map((campus) => (
            <CampusCheckbox
              isAllChecked={isAllChecked}
              key={campus.slug}
              name={campus.name}
              setIsAllChecked={setIsAllChecked}
              slug={campus.slug}
            />
          ));
        }
        return (
          <MetroGroup
            isAllChecked={isAllChecked}
            key={locations[0]}
            locations={locations}
            name={locations[0]}
            setIsAllChecked={setIsAllChecked}
          />
        );
      })}
    </div>
  );
}

CampusCheckboxContainer.propTypes = {
  campuses: PropTypes.shape({}),
  isAllChecked: PropTypes.bool.isRequired,
  setIsAllChecked: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
};

CampusCheckboxContainer.defaultProps = {
  campus: {},
};

export default CampusCheckboxContainer;
