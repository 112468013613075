// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, EVENTS } from '../../types';
import './CountrySelector.scss';

function CountrySelector({ name, slug, value, setToggleDropdown, setCountry }) {
  const isSelected = value === slug;

  function handleClick() {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Counties Container', // Note: This is included within the CountriesContainer component.
        component_url: null,
        label: name,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (!isSelected) {
      setCountry(slug);
    }
    setToggleDropdown(false);
  }

  return (
    <div
      className={`country-checkbox ${isSelected ? 'country-active' : ''}`}
      data-testid="lg-country-selector"
      onClick={handleClick}
    >
      <label className={'lg-country-checkbox'} htmlFor={name}>
        {name}
      </label>
      <span className={`checkbox`} />
    </div>
  );
}

CountrySelector.propTypes = {
  name: PropTypes.string.isRequired,
  setCountry: PropTypes.func.isRequired,
  setToggleDropdown: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default CountrySelector;
