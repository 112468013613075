// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import CampusCheckbox from '../CampusCheckbox';
import FilteredMetroGroup from '../FilteredMetroGroup';
import './FilteredCampusesContainer.scss';

function FilteredCampusesContainer({
  locations,
  isAllChecked,
  setIsAllChecked,
}) {
  return (
    <>
      {locations?.length ? (
        <div
          className="checkbox location-checkbox"
          data-testid="lg-filtered-campuses-container"
        >
          {locations.map((location) => {
            if (typeof location === 'string') {
              return (
                <FilteredMetroGroup
                  isAllChecked={isAllChecked}
                  key={[location, 'Metro'].join(' ')}
                  name={[location, 'Metro'].join(' ')}
                  setIsAllChecked={setIsAllChecked}
                />
              );
            }
            return (
              <CampusCheckbox
                isAllChecked={isAllChecked}
                key={location.name}
                name={location.name}
                setIsAllChecked={setIsAllChecked}
                slug={location.slug}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
}

FilteredCampusesContainer.propTypes = {
  isAllChecked: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  setIsAllChecked: PropTypes.func.isRequired,
};

export default FilteredCampusesContainer;
