/* eslint-disable default-param-last */
import {
  CLEAR_LIFEGROUPS_LIST,
  GOTO_UISTATE,
  REMOVE_CAMPUS,
  REPORT_FETCH_PROGRESS,
  RESET_CAMPUSES,
  RESET_CHURCH_ONLINE_LOCATION,
  RESET_DAYS,
  RESET_OTHER_CATEGORIES,
  SET_AGE,
  SET_CAMPUS,
  SET_CATEGORY,
  SET_CHURCH_ONLINE_LOCATION,
  SET_CHURCH_ONLINE_LOCATIONS,
  SET_DAY,
  SET_GROUP_TYPE,
  SET_KEYWORDS,
  SET_KIDS_WELCOME,
  SET_LIFEGROUPS_LIST,
  SET_LOCATIONS,
  SET_MEET_ONLINE,
  SET_MOBILE_STATUS,
  SET_PARAMS,
  SET_USER,
  TOGGLE_CATEGORY_CHECKBOX_GROUP,
  TOGGLE_DAY_CHECKBOX_GROUP,
  TOGGLE_GROUP_TYPE_CHECKBOX,
  TOGGLE_SEARCH_TRAY,
  UISTATE_SEARCH_PAGE,
} from '../types';

const INITIAL_STATE = {
  age: '',
  campus: {
    id: null,
    name: 'All Locations',
    slug: 'all',
  },
  campusList: [],
  categories: [],
  churchOnlineLocation: {
    id: null,
    name: 'All Locations',
    slug: 'all',
  },
  churchOnlineLocations: [],
  days: [],
  filteredList: ['all'],
  groupTypes: 'lifegroups',
  isCategoryCheckboxGroupToggled: false,
  isDayCheckboxGroupToggled: false,
  isFetching: false,
  isGroupTypeCheckboxToggled: false,
  isMobile: null,
  keywords: '',
  kidsWelcome: 'false',
  lifegroupsList: {
    data: [],
    hasMoreItems: false,
    links: {},
    meta: {},
  },
  meetOnline: 'false',
  searchTrayExpanded: true,
  uiState: UISTATE_SEARCH_PAGE,
  user: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AGE:
      return {
        ...state,
        age: action.payload,
      };
    case SET_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_GROUP_TYPE:
      return {
        ...state,
        groupTypes: action.payload,
      };
    case SET_KIDS_WELCOME:
      return {
        ...state,
        kidsWelcome: action.payload,
      };
    case SET_MEET_ONLINE:
      return {
        ...state,
        meetOnline: action.payload,
      };
    case SET_CAMPUS:
    case REMOVE_CAMPUS:
    case RESET_CAMPUSES:
      return {
        ...state,
        filteredList: action.payload,
      };
    case SET_CHURCH_ONLINE_LOCATION:
      return {
        ...state,
        churchOnlineLocation: action.payload,
      };
    case SET_DAY:
      return {
        ...state,
        days: action.payload,
      };
    case SET_LIFEGROUPS_LIST:
      return {
        ...state,
        lifegroupsList: action.payload,
      };
    case CLEAR_LIFEGROUPS_LIST:
      return {
        ...state,
        lifegroupsList: {
          data: [],
        },
      };
    case SET_LOCATIONS:
      return {
        ...state,
        campusList: action.payload,
      };
    case SET_CHURCH_ONLINE_LOCATIONS:
      return {
        ...state,
        churchOnlineLocations: action.payload,
      };
    case SET_KEYWORDS:
      return {
        ...state,
        keywords: action.payload,
      };
    case TOGGLE_CATEGORY_CHECKBOX_GROUP:
      return {
        ...state,
        isCategoryCheckboxGroupToggled: action.payload,
      };
    case TOGGLE_DAY_CHECKBOX_GROUP:
      return {
        ...state,
        isDayCheckboxGroupToggled: action.payload,
      };
    case TOGGLE_GROUP_TYPE_CHECKBOX:
      return {
        ...state,
        isGroupTypeCheckboxToggled: action.payload,
      };
    case TOGGLE_SEARCH_TRAY:
      return {
        ...state,
        searchTrayExpanded: action.payload,
      };
    case REPORT_FETCH_PROGRESS:
      return {
        ...state,
        isFetching: action.progressOfFetch,
      };
    case SET_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case GOTO_UISTATE:
      return {
        ...state,
        uiState: action.payload,
      };
    case SET_MOBILE_STATUS:
      return {
        ...state,
        isMobile: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case RESET_CHURCH_ONLINE_LOCATION:
      return {
        ...state,
        churchOnlineLocation: { ...INITIAL_STATE.churchOnlineLocation },
      };
    case RESET_DAYS:
      return {
        ...state,
        days: [...INITIAL_STATE.days],
      };
    case RESET_OTHER_CATEGORIES:
      return {
        ...state,
        age: INITIAL_STATE.age,
        categories: INITIAL_STATE.categories,
        kidsWelcome: INITIAL_STATE.kidsWelcome,
        meetOnline: INITIAL_STATE.meetOnline,
      };
    default:
      return state;
  }
};
