/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SearchFilter from '../SearchFilter';
import CountrySelector from '../CountrySelector';
import {
  fetchLifegroupData,
  removeCampus,
  setCampus,
  setChurchOnlineLocation,
} from '../../actions';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, EVENTS } from '../../types';
import './CountryContainer.scss';

function CountryContainer({
  churchOnlineLocation,
  fetchLifegroupData,
  filterFeature,
  filteredList,
  options,
  removeCampus,
  setCampus,
  setChurchOnlineLocation,
  value,
}) {
  const [toggleDropdown, setToggleDropdown] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [filteredLocations, setFilteredLocations] = React.useState([]);
  const ref = React.useRef(null);

  const allowedCharacters = /[^a-z_ ]/gi;

  function handleSearch(event) {
    let text;
    if (typeof event === 'string') {
      text = event;
    } else {
      text = event.target.value.replace(allowedCharacters, '');
    }
    setSearchText(text);
    if (text === '') {
      setFilteredLocations([]);
    } else {
      const firstRegex = `^(${text})`;
      const containsRegex = `${text}`;

      const countries = options.filter((country) => {
        return country.slug !== 'all';
      });

      const firstCountries = countries.filter((country) =>
        country.name.match(new RegExp(firstRegex, 'i')),
      );
      const newCountryList = countries.filter((ele) => {
        return !firstCountries?.includes(ele);
      });

      const containsCountries = newCountryList.filter((country) =>
        country.name.match(new RegExp(containsRegex, 'i')),
      );

      setFilteredLocations([...firstCountries, ...containsCountries]);
    }
  }

  function fullCountry(opts, slug) {
    return opts.find((o) => o.slug === slug);
  }

  function setCountry(slug) {
    handleSearch('');
    /* istanbul ignore next*/
    if (!filteredList?.includes('int')) {
      setCampus('int');
    }
    setChurchOnlineLocation(fullCountry(options, slug));
    fetchLifegroupData();
  }

  function handleDropdownClick() {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Countries Container',
        component_url: null,
        label: toggleDropdown ? ACTIONS.collapsed : ACTIONS.expanded,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    setToggleDropdown(!toggleDropdown);
  }

  React.useEffect(() => {
    if (filteredList?.length > 1 && filteredList?.includes('int')) {
      removeCampus('int');
      setCountry('all');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList]);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        toggleDropdown
      ) {
        setToggleDropdown(!toggleDropdown);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleDropdown]);

  const reorderedOptions = options
    ?.reduce(
      (acc, option) => {
        if (option.slug === 'all') {
          acc[0].unshift(option);
        } else if (option.slug === 'online only') {
          acc[0].push(option);
        } else {
          acc[1].push(option);
        }
        return acc;
      },
      [[], []],
    )
    .flat();

  return (
    <div
      className="countries-container"
      data-cy-dropdown-filter={filterFeature}
      data-testid="lg-country-container"
      ref={ref}
    >
      <p className="mobile-dropdown-header m-b-half font-weight-bold">
        Country
      </p>
      <div
        className={`countries-dropdown
         ${
           toggleDropdown || churchOnlineLocation?.slug !== 'all'
             ? 'lg-blue'
             : 'lg-grey'
         }
        `}
        onClick={handleDropdownClick}
      >
        <div className="menu-text">
          {`${
            churchOnlineLocation.name !== 'All Locations'
              ? churchOnlineLocation.name
              : 'All Countries'
          }`}
        </div>
        <span className={`lg-${toggleDropdown ? 'up' : 'down'}-arrow-zone`}>
          <span className={`lg-${toggleDropdown ? 'up' : 'down'}-arrow`} />
        </span>
      </div>
      <div
        className={`dropdown-outer-container dropdown-outer-${
          toggleDropdown ? 'open' : 'closed'
        }`}
      >
        <SearchFilter handleSearch={handleSearch} searchText={searchText} />
        {filteredLocations.length === 0 ? (
          <>
            {searchText !== '' ? (
              <>
                <p className="no-result font-weight-semibold">
                  0 Results Found
                </p>
                <p className="no-result-description">
                  Didn't find what you are looking for?
                </p>
                <p className="no-result-description">
                  Try another keyword or see all the campuses near you
                </p>
              </>
            ) : (
              reorderedOptions.map((location) => (
                <CountrySelector
                  key={location.name}
                  name={location.name}
                  setCountry={setCountry}
                  setToggleDropdown={setToggleDropdown}
                  slug={location.slug}
                  value={value}
                />
              ))
            )}
          </>
        ) : (
          filteredLocations
            .sort()
            .map((location) => (
              <CountrySelector
                key={location.name}
                name={location.name}
                setCountry={setCountry}
                setToggleDropdown={setToggleDropdown}
                slug={location.slug}
                value={value}
              />
            ))
        )}
      </div>
    </div>
  );
}

function mapStateToProps({ core }) {
  return {
    churchOnlineLocation: core.churchOnlineLocation,
    filteredList: core.filteredList,
  };
}

CountryContainer.propTypes = {
  filterFeature: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, {
  fetchLifegroupData,
  removeCampus,
  setCampus,
  setChurchOnlineLocation,
})(CountryContainer);
