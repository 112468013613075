/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LocationsContainer from '../LocationsContainer';
import GroupTypes from '../GroupTypes';
import DayOfWeekCheckboxes from '../DayOfWeekCheckboxes';
import CategoriesCheckboxes from '../CategoriesCheckboxes';
import { callSegmentTrack, getUser, scrollToTop } from '../../helper';
import { ACTIONS, EVENTS } from '../../types';
import Button from '../Button';
import {
  fetchLifegroupData,
  resetCampuses,
  resetChurchOnlineLocation,
  resetDays,
  resetOtherCategories,
} from '../../actions';
import filterButton from '../../images/sliders-h.svg';
import CountryContainer from '../CountryContainer';
import './SearchOptionsModal.scss';

function SearchOptionsModal(props) {
  const [showModal, setShowModal] = React.useState('hide-modal');

  function showModalHandler(event) {
    /* istanbul ignore next*/
    if (event) {
      event.preventDefault();
    }
    const user = getUser();
    let label = event?.currentTarget?.textContent;
    /* istanbul ignore next */
    if (!label) {
      label = showModal === 'show-modal' ? ACTIONS.hidden : ACTIONS.show;
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Search Options Modal',
        component_url: null,
        label,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    setShowModal(showModal === 'hide-modal' ? 'show-modal' : 'hide-modal');
    scrollToTop();
  }

  function resetFilters() {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Search Options Modal',
        component_url: null,
        label: 'Reset',
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    props.resetDays();
    props.resetChurchOnlineLocation();
    props.resetOtherCategories();
    props.resetCampuses();
    props.fetchLifegroupData();
  }

  function filterCount() {
    let count = 0;
    count += props.days.length ?? /* istanbul ignore next*/ 0;
    count += props.categories.length ?? /* istanbul ignore next*/ 0;
    count += props?.filteredList?.includes('all')
      ? 0
      : props.filteredList.length;
    if (props.kidsWelcome === 'true') {
      count += 1;
    }

    if (props.meetOnline === 'true') {
      count += 1;
    }

    if (props.age.length > 0) {
      count += 1;
    }

    if (props.keywords > 0) {
      count += 1;
    }

    if (props.churchOnlineSlug !== 'all') {
      count += 1;
    }

    return count;
  }

  return (
    <>
      <div className="lg-search-options" data-testid="lg-search-options-modal">
        <div className="row column small-12 small-centered lg-search-container">
          <div className="lg-filter-header-container">
            <div className="lg-header-container">
              <h2 className="lg-header text-left-md">
                Find a LifeGroup or Local Mission Partner
              </h2>

              <span
                className={`lg-filter-btn ${filterCount() > 0 ? 'active' : ''}`}
                onClick={showModalHandler}
              >
                <div className="lg-filter-btn-border">
                  <img
                    alt="Filter button"
                    className="lg-filter-img"
                    src={filterButton}
                  />
                </div>
              </span>
            </div>

            <div className="lg-results-label">{`${props.meta['record-count']} ${
              parseInt(props.meta['record-count'], 10) === 1
                ? 'Result'
                : 'Results'
            } Found`}</div>
          </div>
        </div>
      </div>

      <div
        aria-hidden="true"
        aria-labelledby="modalTitle"
        className={`lc-modal ${showModal}`}
        data-reveal=""
        id="searchOptionsModal"
      >
        <div className="lc-modal-header">
          <a
            aria-label="Close"
            className="lc-modal-close-btn"
            href="/"
            onClick={showModalHandler}
          >
            Close
          </a>
        </div>

        <div className="lc-modal-content">
          <LocationsContainer id="lg-campus-code" />

          {props?.filteredList?.includes('int') ? (
            <CountryContainer
              filterFeature="country"
              id="lg-campus-code"
              mobileLabel="Country"
              options={props.churchOnlineLocations}
              value={props.churchOnlineSlug}
            />
          ) : null}

          <GroupTypes
            classToggle={props.isGroupTypeCheckboxToggled}
            groupTypes={props.groupTypes}
            onChange={props.onGroupTypeChecked}
            onClick={props.onGroupTypeCheckboxClicked}
            toggleCheckboxGroup={props.toggleCheckboxGroup}
            toggleCheckboxGroupArrow={props.toggleCheckboxGroupArrow}
            toggleCheckboxGroupColor={props.toggleCheckboxGroupColor}
          />
          {props.groupTypes !== 'local-partner' && (
            <DayOfWeekCheckboxes
              classToggle={props.isDayCheckboxGroupToggled}
              days={props.days}
              onBlur={props.onDayCheckboxGroupBlurred}
              onChange={props.onDayChecked}
              onClick={props.onDayCheckboxGroupClicked}
              toggleCheckboxGroup={props.toggleCheckboxGroup}
              toggleCheckboxGroupArrow={props.toggleCheckboxGroupArrow}
              toggleCheckboxGroupColor={props.toggleCheckboxGroupColor}
            />
          )}
          <CategoriesCheckboxes
            age={props.age}
            categories={props.categories}
            classGroupTypeToggle={props.isGroupTypeCheckboxToggled}
            classToggle={props.isCategoryCheckboxGroupToggled}
            groupTypes={props.groupTypes}
            kidsWelcome={props.kidsWelcome}
            meetOnline={props.meetOnline}
            moreFiltersContainerRef={props.moreFiltersContainerRef}
            onAgeChanged={props.onAgeChanged}
            onChange={props.onCategoryChecked}
            onClick={props.onCategoryCheckboxGroupClicked}
            onGroupTypeChange={props.onGroupTypeChecked}
            onGroupTypeCheckboxClicked={props.onGroupTypeCheckboxClicked}
            onKidsWelcomeChange={props.onKidsWelcomeChange}
            onMeetOnlineChange={props.onMeetOnlineChange}
            screenSize="desktop"
            toggleCheckboxGroup={props.toggleCheckboxGroup}
            toggleCheckboxGroupArrow={props.toggleCheckboxGroupArrow}
            toggleCheckboxGroupColor={props.toggleCheckboxGroupColor}
          />
          <div className="lg-input-container">
            <div className="lg-inputs">
              {props.groupTypes !== 'local-partner' && (
                <>
                  <label className="font-weight-bold">Your Age</label>
                  <input
                    className="lg-form-age m-r-1"
                    data-cy-age="mobile"
                    maxLength="3"
                    onChange={props.onAgeChanged}
                    pattern="\d*"
                    placeholder="Age"
                    type="text"
                    value={props.age}
                  />
                </>
              )}
              <label className="font-weight-bold">Search Keywords</label>
              <input
                className="lg-form-keyword"
                data-cy-search-keywords=""
                id="lg-keyword"
                onChange={props.onKeywordChanged}
                placeholder="Search Keywords"
                type="text"
                value={props.keywords}
              />
            </div>
          </div>
        </div>

        <div className="lc-modal-footer">
          <div className="row ">
            <div className="column medium-6 text-left lg-results-text hidden-sm">
              {`${props.meta['record-count']} Results Found`}
            </div>

            <div className="column medium-6 text-right text-center-sm lg-modal-btn-group">
              <Button
                btnText={`Reset Filter (${filterCount()})`}
                className="button-secondary button-md"
                onClick={resetFilters}
                type="button"
                value="Reset"
              />

              <Button
                btnText="Apply"
                className="button-primary button-md"
                onClick={showModalHandler}
                type="submit"
                value="Submit"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SearchOptionsModal.propTypes = {
  age: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  churchOnlineSlug: PropTypes.string.isRequired,
  days: PropTypes.array.isRequired,
  filteredList: PropTypes.array.isRequired,
  isCategoryCheckboxGroupToggled: PropTypes.bool.isRequired,
  isDayCheckboxGroupToggled: PropTypes.bool.isRequired,
  keywords: PropTypes.string.isRequired,
  kidsWelcome: PropTypes.string,
  meetOnline: PropTypes.string,
  moreFiltersContainerRef: PropTypes.func,
  onAgeChanged: PropTypes.func.isRequired,
  onCategoryCheckboxGroupClicked: PropTypes.func.isRequired,
  onCategoryChecked: PropTypes.func.isRequired,
  onDayCheckboxGroupBlurred: PropTypes.func.isRequired,
  onDayCheckboxGroupClicked: PropTypes.func.isRequired,
  onDayChecked: PropTypes.func.isRequired,
  onKeywordChanged: PropTypes.func.isRequired,
  onKidsWelcomeChange: PropTypes.func.isRequired,
  onMeetOnlineChange: PropTypes.func.isRequired,
  toggleCheckboxGroup: PropTypes.func.isRequired,
  toggleCheckboxGroupArrow: PropTypes.func.isRequired,
  toggleCheckboxGroupColor: PropTypes.func.isRequired,
};

function mapStateToProps({ core }) {
  const { meta } = core.lifegroupsList;
  return {
    meta,
  };
}

export default connect(mapStateToProps, {
  fetchLifegroupData,
  resetCampuses,
  resetChurchOnlineLocation,
  resetDays,
  resetOtherCategories,
})(SearchOptionsModal);
