export const SET_AGE = 'SET_AGE';
export const SET_CAMPUS = 'SET_CAMPUS';
export const REMOVE_CAMPUS = 'REMOVE_CAMPUS';
export const RESET_CAMPUSES = 'RESET_CAMPUSES';
export const SET_CHURCH_ONLINE_LOCATION = 'SET_CHURCH_ONLINE_LOCATION';
export const RESET_CHURCH_ONLINE_LOCATION = 'RESET_CHURCH_ONLINE_LOCATION';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_GROUP_TYPE = 'SET_GROUP_TYPE';
export const SET_KIDS_WELCOME = 'SET_KIDS_WELCOME';
export const SET_MEET_ONLINE = 'SET_MEET_ONLINE';
export const SET_DAY = 'SET_DAY';
export const RESET_DAYS = 'RESET_DAYS';
export const SET_LIFEGROUPS_LIST = 'SET_LIFEGROUPS_LIST';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_CHURCH_ONLINE_LOCATIONS = 'SET_CHURCH_ONLINE_LOCATIONS';
export const SET_PARAMS = 'SET_PARAMS';
export const CLEAR_LIFEGROUPS_LIST = 'CLEAR_LIFEGROUPS_LIST';
export const SET_KEYWORDS = 'SET_KEYWORDS';
export const TOGGLE_CATEGORY_CHECKBOX_GROUP = 'TOGGLE_CATEGORY_CHECKBOX_GROUP';
export const TOGGLE_DAY_CHECKBOX_GROUP = 'TOGGLE_DAY_CHECKBOX_GROUP';
export const TOGGLE_SEARCH_TRAY = 'TOGGLE_SEARCH_TRAY';
export const TOGGLE_GROUP_TYPE_CHECKBOX = 'TOGGLE_GROUP_TYPE_CHECKBOX';
export const RESET_OTHER_CATEGORIES = 'RESET_OTHER_CATEGORIES';

export const REPORT_FETCH_PROGRESS = 'REPORT_FETCH_PROGRESS';
export const UISTATE_LANDING_PAGE = 'UISTATE_LANDING_PAGE';
export const UISTATE_SEARCH_PAGE = 'UISTATE_SEARCH_PAGE';
export const GOTO_UISTATE = 'GOTO_UISTATE';
export const SET_MOBILE_STATUS = 'SET_MOBILE_STATUS';
export const SET_USER = 'SET_USER';

// Categories
export const DAYS_OF_WEEK = [
  { name: 'Sun', value: 'Su' },
  { name: 'Mon', value: 'Mo' },
  { name: 'Tue', value: 'Tu' },
  { name: 'Wed', value: 'We' },
  { name: 'Thur', value: 'Th' },
  { name: 'Fri', value: 'Fr' },
  { name: 'Sat', value: 'Sa' },
];

export const LIFEGROUP_CATEGORIES = [
  { name: 'Everyone Welcome', value: 'everyone welcome' },
  { name: "Women's", value: "women's" },
  { name: "Men's", value: "men's" },
  { name: 'Singles', value: "single's" },
  { name: 'Married', value: 'married' },
  { name: 'Shared Interests', value: 'shared interest' },
  { name: 'Young Adults', value: 'young adults' },
  { name: 'College', value: 'college' },
  { name: 'Neighbor', value: 'neighbor' },
  { name: 'Mentor', value: 'mentor' },
  { name: 'Watch Party', value: 'watch party' },
];

export const GROUP_TYPES = [
  { name: 'LifeGroups', value: 'lifegroups' },
  { name: 'Local Partner', value: 'local-partner' },
];

// Breadcrumbs
export const EVENT_NAME_CATEGORY_CLICKED = 'Category Clicked';
export const EVENT_NAME_CATEGORY_SELECTED = 'Category Selected';
export const EVENT_NAME_DAY_CLICKED = 'Day Clicked';
export const EVENT_NAME_DAY_SELECTED = 'Day Selected';
export const EVENT_NAME_AGE_CLICKED = 'Age Clicked';
export const EVENT_NAME_AGE_SELECTED = 'Age Selected';
export const EVENT_NAME_CAMPUS_CLICKED = 'Campus Clicked';
export const EVENT_NAME_CAMPUS_SELECTED = 'Campus Selected';
export const EVENT_NAME_KEYWORD_TYPED = 'Keyword Typed';
export const EVENT_NAME_KIDS_WELCOME = 'Kids Welcome';
export const EVENT_NAME_MEET_ONLINE = 'Meets Digitally';
export const EVENT_NAME_SEARCH_TRAY_CLICKED = 'Search Tray Clicked';
export const EVENT_NAME_SEARCH_TRAY_SELECTED = 'Search Tray Selected';
export const EVENT_NAME_GROUP_TYPE_CLICKED = 'Group Type Clicked';
export const EVENT_NAME_GROUP_TYPE_SELECTED = 'Group Type Selected';
export const EVENT_NAME_LIFE_GROUP_CARD_CLICKED = 'LifeGroup Card Click';
export const EVENT_NAME_SCROLL_TO_TOP_CLICKED = 'Jump To Top Button Click';
export const EVENT_NAME_START_LIFEGROUP_CLICKED = 'Start a LifeGroup Click';

// -----------------------------------------------------------------------------
//  Start: Analytics Tracking
// -----------------------------------------------------------------------------

// Actions
export const ACTIONS = {
  changed: 'Changed',
  clicked: 'Clicked',
  closed: 'Closed',
  collapsed: 'Collapsed',
  expanded: 'Expanded',
  hidden: 'Hidden',
  opened: 'Opened',
  selected: 'Selected',
  show: 'Show',
  stopped: 'Stopped',
  tapped: 'Tapped',
  toggledOff: 'Toggled Off',
  toggledOn: 'Toggled On',
};

// Events
export const EVENTS = {
  buttonAction: 'Button Action',
  buttonClick: 'Button Click',
  linkClick: 'Link Click',
  menuItemSelect: 'Menu Item Select',
};

// App Name
export const APP_NAME = 'LifeGroups Search';

// -----------------------------------------------------------------------------
//  End: Analytics Tracking
// -----------------------------------------------------------------------------
