// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLifegroupData, removeCampus, setCampus } from '../../actions';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, EVENTS } from '../../types';
import './LifeChurchOnlineCheckbox.scss';

function LifeChurchOnlineCheckbox({
  filteredList,
  isChecked,
  setIsChecked,
  isAllChecked,
  setIsAllChecked,
  setCampus: setCampusAction,
  removeCampus: removeCampusAction,
  fetchLifegroupData: fetchLifegroupDataAction,
}) {
  function handleClickInt() {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Locations Container', // Note: This component is used within the larger LocationsContainer component.
        component_url: null,
        label: 'Life.Church Online',
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (isChecked && isAllChecked) {
      removeCampusAction('all');
      setIsAllChecked(false);
      setCampusAction('int');
      setIsChecked(true);
    } else if (isChecked) {
      removeCampusAction('int');
      setIsChecked(false);
    } else {
      setCampusAction('int');
      filteredList.forEach((slug) => {
        if (slug !== 'int') {
          removeCampusAction(slug);
        }
      });
      setIsChecked(true);
    }
    fetchLifegroupDataAction();
  }

  React.useEffect(() => {
    const anyOfRegex = /\b(?:int|all)\b/gi;
    const isLifeChurchOnlineChecked = filteredList.some((item) =>
      anyOfRegex.test(item),
    );
    setIsChecked(isLifeChurchOnlineChecked);
  }, [filteredList, setIsChecked]);

  return (
    <div
      className="checkbox location-checkbox"
      data-testid="lg-lifechurch-online-checkbox"
      key="Global"
    >
      <label className="lg-location-checkbox state-text" htmlFor="Global">
        Global
      </label>
      <div className="campus-checkbox lco-checkbox" onClick={handleClickInt}>
        <div className={`lg-location-checkbox`}>Life.Church Online</div>
        <span className={`checkbox ${isChecked ? 'campus-active' : ''}`} />
      </div>
    </div>
  );
}

function mapStateToProps({ core }) {
  return {
    filteredList: core.filteredList,
  };
}

LifeChurchOnlineCheckbox.propTypes = {
  fetchLifegroupData: PropTypes.func.isRequired,
  filteredList: PropTypes.array.isRequired,
  isAllChecked: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  removeCampus: PropTypes.func.isRequired,
  setCampus: PropTypes.func.isRequired,
  setIsAllChecked: PropTypes.func.isRequired,
  setIsChecked: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchLifegroupData,
  removeCampus,
  setCampus,
})(LifeChurchOnlineCheckbox);
