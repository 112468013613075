// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import './CheckBox.scss';

function CheckBox(props) {
  const classNameValue =
    props.labelClassName + (props.isChecked ? ' lg-active' : '');
  return (
    <div className="checkbox-div" data-testid="lg-checkbox">
      <input
        checked={props.isChecked}
        className="lg-hidden-checkbox"
        id={props.id}
        onChange={props.onChange}
        type="checkbox"
        value={props.value}
      />
      <label
        className={`lg-checkbox ${classNameValue}`}
        data-cy-grouptype={props.cypress}
        htmlFor={props.id}
      >
        {props.name}
      </label>
    </div>
  );
}

CheckBox.propTypes = {
  cypress: PropTypes.string,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default CheckBox;
