// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../CheckBox';
import CustomCheckBox from '../CheckBox/CustomCheckBox';
import { callSegmentTrack, getUser, normalize } from '../../helper';
import {
  ACTIONS,
  EVENTS,
  GROUP_TYPES,
  LIFEGROUP_CATEGORIES,
} from '../../types';
import './CategoriesCheckboxes.scss';

function CategoriesCheckboxes(props) {
  const categoriesLength =
    props.categories.length +
    (props.age !== '' ? 1 : 0) +
    (props.kidsWelcome === 'true' ? 1 : 0) +
    (props.meetOnline === 'true' ? 1 : 0);

  const renderCheckboxes = () => {
    const isChecked = (value) => {
      return props?.categories?.includes(value);
    };

    /**
     * Handler function for checkbox click.
     *
     * @param {object} params - The function params object.
     * @param {string} params.label - The checkbox label.
     */
    function handleCheckboxClick({ label }) {
      const user = getUser();
      callSegmentTrack({
        event: EVENTS.buttonAction,
        properties: {
          action: ACTIONS.clicked,
          component: 'Categories Container - Categories Button',
          component_url: null,
          label,
          logged_in: !!user,
          preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
          referrer: document?.referrer || null,
          title: document?.title || '',
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });
    }

    return LIFEGROUP_CATEGORIES.map((lifegroup) => (
      <CheckBox
        id={lifegroup.value}
        isChecked={isChecked(lifegroup.value)}
        key={lifegroup.value}
        labelClassName={`lg-category-checkbox lg-category-${normalize(
          lifegroup.value,
        )}`}
        name={lifegroup.name}
        onChange={(event) => {
          handleCheckboxClick({ label: lifegroup.name });
          props.onChange(event);
        }}
        value={lifegroup.value}
      />
    ));
  };

  const renderGroupTypeCheckboxes = () => {
    const isChecked = (value) => {
      return props?.groupTypes?.includes(value);
    };

    /**
     * Handler function for checkbox click.
     *
     * @param {object} params - The function params object.
     * @param {string} params.label - The checkbox label.
     */
    function handleCheckboxClick({ label }) {
      const user = getUser();
      callSegmentTrack({
        event: EVENTS.buttonAction,
        properties: {
          action: ACTIONS.clicked,
          component: 'Categories Container - Group Type Button',
          component_url: null,
          label,
          logged_in: !!user,
          preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
          referrer: document?.referrer || null,
          title: document?.title || '',
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });
    }

    return GROUP_TYPES.map((groupType) => (
      <CheckBox
        cypress={`desktop-${groupType.value}`}
        id={groupType.value.toLowerCase()}
        isChecked={isChecked(groupType.value)}
        key={groupType.value}
        labelClassName="lg-group-type-checkbox"
        name={groupType.name}
        onChange={(event) => {
          handleCheckboxClick({ label: groupType.name });
          props.onGroupTypeChange(event);
        }}
        onClick={props.onGroupTypeCheckboxClicked}
        value={groupType.value}
      />
    ));
  };

  /**
   * Handler function for other filter checkbox click.
   *
   * @param {object} params - The function params object.
   * @param {boolean} params.isChecked - Boolean flag denoting whether or not the checkbox is checked.
   * @param {string} params.label - The checkbox label.
   */
  function handleOtherFiltersClick({ isChecked, label }) {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Categories Container - Other Filters Checkbox',
        component_url: null,
        label: `${label} - ${
          isChecked.toString() === 'true'
            ? ACTIONS.toggledOn
            : ACTIONS.toggledOff
        }`,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  return (
    <div
      className="lg-checkbox-group-container lc-categories-container"
      data-testid="lg-categories-checkboxes"
      onBlur={props.onBlur}
      onClick={props.onClick}
      tabIndex={-1}
    >
      <div
        className={`lg-checkbox-group category ${
          categoriesLength > 0
            ? 'lg-blue'
            : props.toggleCheckboxGroupColor(props.classToggle)
        }`}
        data-cy-more-filters=""
        id="id_more_filters_container_button"
      >
        <div className="menu-text">
          {`More Filters ${
            categoriesLength > 0 ? `(${categoriesLength})` : ''
          }`}
        </div>
        <span
          className={`lg-${props.toggleCheckboxGroupArrow(
            props.classToggle,
          )}-arrow-zone`}
        >
          <span
            className={`lg-${props.toggleCheckboxGroupArrow(
              props.classToggle,
            )}-arrow`}
          />
        </span>
      </div>
      <div
        className={`lg-checkbox-container category ${props.toggleCheckboxGroup(
          props.classToggle,
        )}`}
        data-cy-more-filters-dropdown=""
        data-testid="lg-filters-container"
        id="id_more_filters_container"
      >
        <div className="in-category-group-type-container" tabIndex={-1}>
          <p className="group-view m-b-half font-weight-bold color-LC-black">
            Group Types
          </p>
          <div
            className={`${props.toggleCheckboxGroup(
              props.onClassGroupTypeToggle,
            )}`}
            data-cy-days-dropdown=""
          >
            <div className="in-category-group-type-render">
              {renderGroupTypeCheckboxes()}
            </div>
          </div>
        </div>
        {props.groupTypes !== 'local-partner' && (
          <div className="lg-category-checkbox-container">
            <p className="lg-checkbox-group-text categories-text color-LC-black font-weight-bold">
              Categories
            </p>
            {renderCheckboxes()}
          </div>
        )}
        {props.groupTypes === 'local-partner' && (
          <div className="lg-checkbox-group-container switch-online-blurb">
            <p>
              Are you interested in getting connected to a Local Mission
              Partner? Check out the local organizations our church partners
              with, and reach out to them to learn more about serving with that
              partner.
            </p>
          </div>
        )}
        {props.groupTypes !== 'local-partner' && (
          <div className="lg-form-age-container">
            <p className="categories-text categories-age-text m-b-half text-bold">
              Your Age
            </p>
            <input
              className="lg-category-form-age m-l-0 m-b-1"
              data-cy-age={props.screenSize}
              maxLength="3"
              onChange={props.onAgeChanged}
              pattern="\d*"
              placeholder="Age"
              type="text"
              value={props.age}
            />
          </div>
        )}
        {props.groupTypes !== 'local-partner' && (
          <div className="categories-custom-checkbox-container">
            <p className="lg-checkbox-group-text categories-checkbox-text categories-text text-bold">
              Other Filters
            </p>
            <CustomCheckBox
              class="kids-welcome m-b-1"
              filterFeature="kids-welcome"
              id="children-welcome"
              isChecked={props.kidsWelcome}
              labelClassName="lg-checkbox-children-welcome"
              name="Kids are welcome at our LifeGroup"
              onChange={(event) => {
                handleOtherFiltersClick({
                  isChecked: props.kidsWelcome,
                  label: 'Kids are welcome at our LifeGroup',
                });
                props.onKidsWelcomeChange(event);
              }}
              value={props.kidsWelcome}
            />
            <CustomCheckBox
              class="meet-online"
              filterFeature="meet-online"
              id="online-exclusive"
              isChecked={props.meetOnline}
              labelClassName="lg-checkbox-online-exclusive"
              name="Only show LifeGroups that meet digitally"
              onChange={(event) => {
                handleOtherFiltersClick({
                  isChecked: props.meetOnline,
                  label: 'Only show LifeGroups that meet digitally',
                });
                props.onMeetOnlineChange(event);
              }}
              value={props.meetOnline}
            />
          </div>
        )}
      </div>
    </div>
  );
}

CategoriesCheckboxes.propTypes = {
  age: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  classToggle: PropTypes.bool.isRequired,
  kidsWelcome: PropTypes.string.isRequired,
  meetOnline: PropTypes.string.isRequired,
  onAgeChanged: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onKidsWelcomeChange: PropTypes.func.isRequired,
  onMeetOnlineChange: PropTypes.func.isRequired,
  screenSize: PropTypes.string,
  toggleCheckboxGroup: PropTypes.func.isRequired,
  toggleCheckboxGroupArrow: PropTypes.func.isRequired,
  toggleCheckboxGroupColor: PropTypes.func.isRequired,
};

export default CategoriesCheckboxes;
