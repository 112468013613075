/**
 * @module Helper
 */
import { DAYS_OF_WEEK } from './types';

/**
 * @typedef {object} AppMetadata
 * @property {string} address - User street address.
 * @property {string} address_2 - User street address (continued).
 * @property {string} campus - Campus value.
 * @property {string} campus_code - Campus code.
 * @property {string} city - User city.
 * @property {string} country - User country.
 * @property {string} date_of_birth - User date of birth.
 * @property {boolean} deleted - Boolean flag denoting whether or not deletion has occurred.
 * @property {string} deleted_on - Date/Time value of deletion.
 * @property {string} f1_barcode - Custom field value.
 * @property {string} f1_hh_id - Custom field value.
 * @property {string} f1_id - Custom field value.
 * @property {string} f1_organization_id - Custom field value.
 * @property {string} family_name - User family (last) name.
 * @property {string} former_name - User former name.
 * @property {string} gender - User gender.
 * @property {string} given_name - User given name.
 * @property {boolean} is_organization - Boolean flag denoting organization.
 * @property {string} middle_name - User middle name.
 * @property {string} organization_name - Organization name value.
 * @property {Array} phones - Array of user phone numbers.
 * @property {string} postal - User address postal code.
 * @property {string} prefix - User name prefix.
 * @property {string} rock_person_alias_id - Rock RMS person alias id.
 * @property {string} state - User address state.
 * @property {string} suffix - User name suffix.
 * @property {boolean} verification_email_sent - Boolean flag denoting status of verification email sent.
 */

/**
 * @typedef {object} UserMetadata
 * @property {string} can_email - String-based boolean value of user email preference (e.g. 'false').
 * @property {string} first_name - User first name (e.g. 'John').
 * @property {string} last_name - User last name (e.g. 'Doe').
 */

/**
 * @typedef {object} User
 * @property {string} family_name - User family (last) name (e.g. 'Doe').
 * @property {string} given_name - User given (first) name (e.g. 'John').
 * @property {AppMetadata} app_metadata [https://www.life.church/app_metadata] - App metadata object.
 * @property {number} rock_person_alias_id [https://www.life.church/rock_person_alias_id] - Rock RMS person alias id (e.g. 8675309).
 * @property {UserMetadata} user_metadata [https://www.life.church/user_metadata] - User metadata object.
 * @property {string} name - User display name (e.g. 'John Doe').
 * @property {string} nickname - User nickname value (e.g. 'john.doe').
 * @property {string} picture - URL string of user picture (e.g. 'https://s.gravatar.com/avatar/abc123').
 * @property {string} sub - Authentication user id value (e.g. 'auth0|8jj67w5c3irt0j9j8j067wci').
 * @property {string} updated_at - Date/Time value of last updated (e.g. '2023-01-01T12:00:00.000Z').
 */

/**
 * @typedef SerializationOptions
 *
 * @param {Function} serialize - The serialization function (default: JSON.stringify).
 * @param {Function} deserialize - The deserialization function (default: JSON.parse).
 */

export function truncate(str, limit) {
  if (!limit || str.length <= limit) {
    return str;
  }
  const newStr = str.substring(0, limit);
  return `${newStr}...`;
}

export function normalize(str) {
  return str.toLowerCase().replace(' ', '-').replace("'", '');
}

export function scrollToTop() {
  window.scrollTo({
    behavior: 'smooth',
    left: 0,
    top: 0,
  });
}

/**
 * Convenience function to fire off a Segment Page call.
 *
 * Since this site is in an iframe on the Life.Church site, there is a need
 * to call `postMessage` to the parent window, which has the appropriate logic
 * in place to listen for the message, applicable event.data attributes, and
 * call Segment analytics for tracking.
 *
 * @param {object} params - The function parameters object.
 * @param {string} params.name - The name for the Page call.
 * @param {object} params.properties - The properties object associated with the Page call.
 *
 * @throws {Error} - Throws an error if `name` or `properties` are not provided or are invalid.
 * @throws {Error} - Throws an error if call to track is unsuccessful.
 */
export function callSegmentPage({ name, properties }) {
  if (!name || !properties || !Object.keys(properties).length) {
    throw new Error(
      `A valid name (string) and properties (object) must be specified.`,
    );
  }

  if (window.parent) {
    const data = {
      channel: 'segment',
      data: {
        category: properties.title || name,
        name,
        properties,
        type: 'page',
      },
      type: 'page',
    };
    window.parent.postMessage(data, '*'); // NOSONAR
  }
}

/**
 * Convenience function to fire off a Segment Screen call.
 *
 * Since this site is in an iframe on the Life.Church site, there is a need
 * to call `postMessage` to the parent window, which has the appropriate logic
 * in place to listen for the message, applicable event.data attributes, and
 * call Segment analytics for tracking.
 *
 * @param {object} params - The function parameters object.
 * @param {string} params.name - The name for the Screen call.
 * @param {object} params.properties - The properties object associated with the Screen call.
 *
 * @throws {Error} - Throws an error if `name` or `properties` are not provided or are invalid.
 * @throws {Error} - Throws an error if call to track is unsuccessful.
 */
export function callSegmentScreen({ name, properties }) {
  if (!name || !properties || !Object.keys(properties).length) {
    throw new Error(
      `A valid name (string) and properties (object) must be specified.`,
    );
  }

  if (window.parent) {
    const data = {
      channel: 'segment',
      data: { name, properties, type: 'screen' },
      type: 'screen',
    };
    window.parent.postMessage(data, '*'); // NOSONAR
  }
}

/**
 * Convenience function to fire off a Segment Track call.
 *
 * Since this site is in an iframe on the Life.Church site, there is a need
 * to call `postMessage` to the parent window, which has the appropriate logic
 * in place to listen for the message, applicable event.data attributes, and
 * call Segment analytics for tracking.
 *
 * @param {object} params - The function parameters object.
 * @param {string} params.event - The event for the Track call.
 * @param {object} params.properties - The properties object associated with the Track call.
 *
 * @throws {Error} - Throws an error if `event` or `properties` are not provided or are invalid.
 * @throws {Error} - Throws an error if call to track is unsuccessful.
 */
export function callSegmentTrack({ event, properties }) {
  if (!event || !properties || !Object.keys(properties).length) {
    throw new Error(
      `A valid event (string) and properties (object) must be specified.`,
    );
  }

  if (window.parent) {
    const data = {
      channel: 'segment',
      data: { event, properties, type: 'track' },
      type: 'track',
    };
    window.parent.postMessage(data, '*'); // NOSONAR
  }
}

/**
 * Convenience function to retrieve three-character day of the week label.
 *
 * @param {string} value - The two-character day of week value used for lookup.
 *
 * @returns {*} - The three-character day of the week label, or the passed-in value if no mapping match found.
 */
export function getDayOfWeekLabel(value) {
  const day = DAYS_OF_WEEK.find((d) => d.value === value);
  return day !== undefined ? day.name : value;
}

/**
 * Convenience function to retrieve localStorage item, if found. Returns `null` if not found.
 *
 * @param {string} key - The key of the localStorage item.
 * @param {SerializationOptions} serializationOptions - Object of options.
 *
 * @returns {*} - The value of the local storage item.
 */
export function getLocalStorageItem(key, { deserialize = JSON.parse } = {}) {
  const valueInLocalStorage = window.localStorage.getItem(key);
  if (valueInLocalStorage) {
    try {
      return deserialize(valueInLocalStorage);
    } catch (error) {
      return null;
    }
  }
  return null;
}

/**
 * Convenience function to retrieve the stored user data object from local storage.
 *
 * @returns {User} The user data object.
 */
export function getUser() {
  const user = getLocalStorageItem('user_profile');
  return user;
}
