// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from '../CheckBox';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, DAYS_OF_WEEK, EVENTS } from '../../types';
import './DayOfWeekCheckboxes.scss';

function DayOfWeekCheckboxes(props) {
  const daysLength = props.days.filter((day) => day !== '??').length;
  const daysLengthValue = daysLength > 0 ? `(${daysLength})` : '';
  const lgCheckboxGroupDayClassName =
    daysLength > 0
      ? 'lg-blue'
      : props.toggleCheckboxGroupColor(props.classToggle);
  const renderCheckboxes = () => {
    const isChecked = (value) => {
      return props?.days?.includes(value);
    };

    /**
     * Handler function for checkbox click.
     *
     * @param {object} params - The function params object.
     * @param {string} params.label - The checkbox label.
     */
    function handleCheckboxClick({ label }) {
      const user = getUser();
      callSegmentTrack({
        event: EVENTS.buttonAction,
        properties: {
          action: ACTIONS.clicked,
          component: 'Day of Week Container Button',
          component_url: null,
          label,
          logged_in: !!user,
          preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
          referrer: document?.referrer || null,
          title: document?.title || '',
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });
    }

    return DAYS_OF_WEEK.map((day) => (
      <CheckBox
        id={day.value.toLowerCase()}
        isChecked={isChecked(day.value)}
        key={day.value}
        labelClassName="lg-day-checkbox"
        name={day.name}
        onChange={(event) => {
          handleCheckboxClick({ label: day.name });
          props.onChange(event);
        }}
        value={day.value}
      />
    ));
  };

  return (
    <div
      className="lg-checkbox-group-container"
      data-testid="lg-day-of-week-checkboxes"
      onBlur={props.onBlur}
      onClick={props.onClick}
      tabIndex={-1}
    >
      <p className="mobile-days-text m-b-half font-weight-bold color-LC-black">
        Meeting Day
      </p>
      <div
        className={`lg-checkbox-group day ${lgCheckboxGroupDayClassName}`}
        data-cy-days=""
      >
        <div className="menu-text">{`Days ${daysLengthValue}`}</div>
        <span
          className={`lg-${props.toggleCheckboxGroupArrow(
            props.classToggle,
          )}-arrow-zone`}
        >
          <span
            className={`lg-${props.toggleCheckboxGroupArrow(
              props.classToggle,
            )}-arrow`}
          />
        </span>
      </div>
      <div
        className={`lg-checkbox-container day ${props.toggleCheckboxGroup(
          props.classToggle,
        )}`}
        data-cy-days-dropdown=""
      >
        <p className="lg-checkbox-group-text desktop-days-text">Meeting Day</p>
        <div className="lg-day-checkbox-container">{renderCheckboxes()}</div>
      </div>
    </div>
  );
}

DayOfWeekCheckboxes.propTypes = {
  classToggle: PropTypes.bool.isRequired,
  days: PropTypes.array.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  toggleCheckboxGroup: PropTypes.func.isRequired,
  toggleCheckboxGroupArrow: PropTypes.func.isRequired,
  toggleCheckboxGroupColor: PropTypes.func.isRequired,
};

export default DayOfWeekCheckboxes;
