/* eslint-disable no-shadow */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLifegroupData, removeCampus, setCampus } from '../../actions';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, EVENTS } from '../../types';
import './CampusCheckbox.scss';

function CampusCheckbox({
  name,
  fetchLifegroupData,
  filteredList,
  isAllChecked,
  isMetroChecked,
  removeCampus,
  setCampus,
  setIsAllChecked,
  setIsMetroChecked,
  setIsPartial,
  slug,
}) {
  let isChecked = filteredList?.includes(slug) || filteredList?.includes('all');

  function handleClick() {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: isChecked ? ACTIONS.toggledOff : ACTIONS.toggledOn,
        component: 'Locations Container', // Note: This component is used within the larger LocationsContainer component.
        component_url: null,
        label: name,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (isChecked) {
      if (isAllChecked) {
        setIsAllChecked(false);
        removeCampus('all');
        setCampus(slug);
      } else {
        isChecked = false;
        removeCampus(slug);
      }
    } else {
      isChecked = true;
      setCampus(slug);
    }
    if (setIsMetroChecked !== null && setIsPartial !== null) {
      setIsMetroChecked(false);
      setIsPartial(true);
    }
    fetchLifegroupData();
  }

  return (
    <div
      className="campus-checkbox"
      data-testid="lg-campus-checkbox"
      onClick={handleClick}
    >
      <label className={`lg-location-checkbox`} htmlFor={name}>
        {name}
      </label>
      <span
        className={`checkbox ${
          isMetroChecked || isChecked ? 'campus-active' : ''
        }`}
      />
    </div>
  );
}

function mapStateToProps({ core }) {
  return {
    filteredList: core.filteredList,
  };
}

CampusCheckbox.propTypes = {
  isAllChecked: PropTypes.bool.isRequired,
  isMetroChecked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  setIsAllChecked: PropTypes.func.isRequired,
  setIsMetroChecked: PropTypes.func,
  setIsPartial: PropTypes.func,
  slug: PropTypes.string.isRequired,
};

CampusCheckbox.defaultProps = {
  isMetroChecked: null,
  setIsPartial: null,
};

export default connect(mapStateToProps, {
  fetchLifegroupData,
  removeCampus,
  setCampus,
})(CampusCheckbox);
