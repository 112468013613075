// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  return (
    <button
      className={`button ${props.className}`}
      disabled={props.disabled}
      onClick={props.onClick}
      tabIndex={props.tabIndex}
      type={props.type}
      value={props.value}
    >
      {props.btnText}
    </button>
  );
};

Button.defaultProps = {
  className: '',
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default Button;
