import { combineReducers } from 'redux';
import CoreReducer from './CoreReducer';
import AnalyticsReducer from './AnalyticsReducer';

const allReducers = combineReducers({
  analytics: AnalyticsReducer,
  core: CoreReducer,
});

export default allReducers;
