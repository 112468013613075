/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import ChurchOnlineBanner from '../ChurchOnlineBanner';
import {
  fetchLifegroupData,
  removeCampus,
  resetChurchOnlineLocation,
  resetDays,
  resetOtherCategories,
  setAge,
  setCampus,
  setCategory,
  setChurchOnlineLocation,
  setDay,
  setGroupType,
  setKeywords,
  setKidsWelcome,
  setMeetOnline,
  toggleCategoryCheckboxGroup,
  toggleDayCheckboxGroup,
  toggleGroupTypeCheckbox,
  toggleSearchTray,
} from '../../actions';
import SearchOptions from '../SearchOptions';
import SearchOptionsModal from '../SearchOptionsModal';
import './SearchContainer.scss';

/**
 * Represents a container for holding search elements.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} The SearchContainer component.
 */
function SearchContainer(props) {
  /**
   * Handler function for day checked event.
   *
   * @param {Event} event - The function Event object.
   */
  function onDayChecked(event) {
    props.setDay(event.target.value);
    props.fetchLifegroupData();
  }

  /**
   * Handler function for group type checked event.
   *
   * @param {Event} event - The function Event object.
   */
  /* istanbul ignore next*/
  function onGroupTypeChecked(event) {
    props.setGroupType(event.target.value);
    if (event.target.value === 'local-partner') {
      props.resetOtherCategories();
      props.fetchLifegroupData();
    } else {
      props.fetchLifegroupData();
    }
  }

  /**
   * Handler function for category checked event.
   *
   * @param {Event} event - The function Event object.
   */
  function onCategoryChecked(event) {
    props.setCategory(event.target.value);
    props.fetchLifegroupData();
  }

  /**
   * Handler function for group type checkbox click event.
   *
   * @param {Event} event - The function Event object.
   */
  /* istanbul ignore next*/
  function onGroupTypeCheckboxClicked(event) {
    props.setGroupType(event.target.value);
    props.fetchLifegroupData();
    props.toggleGroupTypeCheckbox(props.isGroupTypeCheckboxToggled);
  }

  /**
   * Handler function for kids welcome change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onKidsWelcomeChange(event) {
    props.setKidsWelcome(event.target.value);
    props.fetchLifegroupData();
  }

  /**
   * Handler function for meet online change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onMeetOnlineChange(event) {
    props.setMeetOnline(event.target.value);
    props.fetchLifegroupData();
  }

  /**
   * Handler function for age change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onAgeChanged(event) {
    const { value } = event.target;
    props.setAge(value);
    if (value.toString().length > 1 || value.toString().length === 0) {
      props.fetchLifegroupData();
    }
  }

  /**
   * Handler function for keyword change event.
   *
   * @param {Event} event - The function Event object.
   */
  function onKeywordChanged(event) {
    const { value } = event.target;
    props.setKeywords(value);
    if (value.length >= 3 || value.length === 0) {
      props.fetchLifegroupData();
    }
  }

  /**
   * Convenience function to return class name for minimize search.
   *
   * @param {boolean} value - Boolean value denoting search content minimize necessity. When falsey, minimize class value returned.
   *
   * @returns {string} The class name value.
   */
  function minimizeSearch(value) {
    return value ? '' : 'lg-minimize';
  }

  /**
   * Convenience function to retrieve and return the "more filters" container HTML element.
   *
   * @returns {React.ReactElement} The "more filters" container HTML element.
   */
  function moreFiltersContainer() {
    return document.getElementById('id_more_filters_container');
  }

  /**
   * Handler function for category checkbox group click event.
   *
   * @param {Event} event - The function Event object.
   *
   * @returns {Function} Toggle function invoked for category checkbox group.
   */
  function onCategoryCheckboxGroupClicked(event) {
    if (props.isCategoryCheckboxGroupToggled) {
      if (
        moreFiltersContainer() &&
        !event.target.closest('#id_more_filters_container')
      ) {
        return props.toggleCategoryCheckboxGroup(false);
      }
    }
    return props.toggleCategoryCheckboxGroup(true);
  }

  /**
   * Handler function for day checkbox group click event.
   */
  function onDayCheckboxGroupClicked() {
    props.toggleDayCheckboxGroup(props.isDayCheckboxGroupToggled);
  }

  /**
   * Handler function for day checkbox group blur event.
   */
  function onDayCheckboxGroupBlurred() {
    const { isDayCheckboxGroupToggled, toggleDayCheckboxGroup } = props;
    if (isDayCheckboxGroupToggled) {
      toggleDayCheckboxGroup(isDayCheckboxGroupToggled);
    }
  }

  /**
   * Handler function for group type check group blur event.
   */
  /* istanbul ignore next */
  function onGroupTypeCheckboxBlurred() {
    const { isGroupTypeCheckboxToggled, toggleGroupTypeCheckbox } = props;
    if (isGroupTypeCheckboxToggled) {
      toggleGroupTypeCheckbox(isGroupTypeCheckboxToggled);
    }
  }

  /**
   * Convenience function to return class name for checkbox group.
   *
   * @param {boolean} value - Boolean value denoting whether or not to show checkbox group. When falsey, hide class value returned.
   *
   * @returns {string} The class name value.
   */
  function toggleCheckboxGroup(value) {
    return value ? 'lg-show-dropdown' : 'lg-hide-dropdown';
  }

  /**
   * Convenience function to return class name for checkbox group.
   *
   * @param {boolean} value - Boolean value denoting which color class to use. When truthy, 'lg-blue' is returned, and when falsey, 'lg-grey' is returned.
   *
   * @returns {string} The class name value.
   */
  function toggleCheckboxGroupColor(value) {
    return value ? 'lg-blue' : 'lg-grey';
  }

  /**
   * Convenience function to return class name for checkbox group arrow.
   *
   * @param {boolean} value - Boolean value denoting checkbox group arrow direction. When truthy, 'up' is returned, and when falsey, 'down' is returned.
   *
   * @returns {string} The class name value.
   */
  function toggleCheckboxGroupArrow(value) {
    return value ? 'up' : 'down';
  }

  /**
   * Single-run convenience effect to capture and handle mouse event listeners.
   */
  React.useEffect(() => {
    /**
     * Handler function for outside click.
     *
     * Note: This handler only triggers change for toggle of category checkbox
     * group if event target is neither the main menu container or button.
     *
     * @param {Event} event - The Event object associated with the click.
     */
    /* istanbul ignore next*/
    function handleClickOutside(event) {
      if (
        !event.target.closest('#id_more_filters_container_button') &&
        !event.target.closest('#id_more_filters_container')
      ) {
        props.toggleCategoryCheckboxGroup(
          Boolean(
            moreFiltersContainer() &&
              event.target.closest('#id_more_filters_container'),
          ),
        );
      }
    }

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`lifegroups-search ${minimizeSearch(
        props.searchTrayExpanded,
      )}`}
      data-testid="lg-search-container"
      id="lg-to-the-top"
    >
      <ChurchOnlineBanner
        slug={props?.campus?.slug ?? 'all'}
        user={props.user}
      />
      <MediaQuery query="(max-device-width: 768px)">
        <SearchOptionsModal
          age={props.age}
          campus={props.campus}
          campusList={props.campusList}
          campusSlug={props?.campus?.slug ?? 'all'}
          categories={props.categories}
          churchOnlineLocations={props.churchOnlineLocations}
          churchOnlineSlug={props.churchOnlineLocation.slug}
          days={props.days}
          filteredList={props.filteredList}
          groupTypes={props.groupTypes}
          isCategoryCheckboxGroupToggled={props.isCategoryCheckboxGroupToggled}
          isDayCheckboxGroupToggled={props.isDayCheckboxGroupToggled}
          isGroupTypeCheckboxToggled={props.isGroupTypeCheckboxToggled}
          keywords={props.keywords}
          kidsWelcome={props.kidsWelcome}
          meetOnline={props.meetOnline}
          onAgeChanged={onAgeChanged}
          onCategoryCheckboxGroupClicked={onCategoryCheckboxGroupClicked}
          onCategoryChecked={onCategoryChecked}
          onDayCheckboxGroupBlurred={onDayCheckboxGroupBlurred}
          onDayCheckboxGroupClicked={onDayCheckboxGroupClicked}
          onDayChecked={onDayChecked}
          onGroupTypeCheckboxBlurred={onGroupTypeCheckboxBlurred}
          onGroupTypeCheckboxClicked={onGroupTypeCheckboxClicked}
          onGroupTypeChecked={onGroupTypeChecked}
          onKeywordChanged={onKeywordChanged}
          onKidsWelcomeChange={onKidsWelcomeChange}
          onMeetOnlineChange={onMeetOnlineChange}
          toggleCheckboxGroup={toggleCheckboxGroup}
          toggleCheckboxGroupArrow={toggleCheckboxGroupArrow}
          toggleCheckboxGroupColor={toggleCheckboxGroupColor}
        />
      </MediaQuery>

      <MediaQuery query="(min-device-width: 769px)">
        <SearchOptions
          age={props.age}
          campus={props.campus}
          campusList={props.campusList}
          categories={props.categories}
          churchOnlineLocations={props.churchOnlineLocations}
          churchOnlineSlug={props.churchOnlineLocation.slug}
          days={props.days}
          filteredList={props.filteredList}
          groupTypes={props.groupTypes}
          isCategoryCheckboxGroupToggled={props.isCategoryCheckboxGroupToggled}
          isDayCheckboxGroupToggled={props.isDayCheckboxGroupToggled}
          isGroupTypeCheckboxToggled={props.isGroupTypeCheckboxToggled}
          keywords={props.keywords}
          kidsWelcome={props.kidsWelcome}
          meetOnline={props.meetOnline}
          onAgeChanged={onAgeChanged}
          onCategoryCheckboxGroupClicked={onCategoryCheckboxGroupClicked}
          onCategoryChecked={onCategoryChecked}
          onDayCheckboxGroupBlurred={onDayCheckboxGroupBlurred}
          onDayCheckboxGroupClicked={onDayCheckboxGroupClicked}
          onDayChecked={onDayChecked}
          onGroupTypeCheckboxBlurred={onGroupTypeCheckboxBlurred}
          onGroupTypeCheckboxClicked={onGroupTypeCheckboxClicked}
          onGroupTypeChecked={onGroupTypeChecked}
          onKeywordChanged={onKeywordChanged}
          onKidsWelcomeChange={onKidsWelcomeChange}
          onMeetOnlineChange={onMeetOnlineChange}
          toggleCheckboxGroup={toggleCheckboxGroup}
          toggleCheckboxGroupArrow={toggleCheckboxGroupArrow}
          toggleCheckboxGroupColor={toggleCheckboxGroupColor}
        />
      </MediaQuery>
    </div>
  );
}

function mapStateToProps({ core }) {
  return {
    age: core.age,
    campus: core.campus,
    campusList: core.campusList,
    categories: core.categories,
    churchOnlineLocation: core.churchOnlineLocation,
    churchOnlineLocations: core.churchOnlineLocations,
    days: core.days,
    filteredList: core.filteredList,
    groupTypes: core.groupTypes,
    isCategoryCheckboxGroupToggled: core.isCategoryCheckboxGroupToggled,
    isDayCheckboxGroupToggled: core.isDayCheckboxGroupToggled,
    isGroupTypeCheckboxToggled: core.isGroupTypeCheckboxToggled,
    keywords: core.keywords,
    kidsWelcome: core.kidsWelcome,
    lifegroupsList: core.lifegroupsList,
    meetOnline: core.meetOnline,
    searchTrayExpanded: core.searchTrayExpanded,
  };
}

SearchContainer.propTypes = {
  // State
  churchOnlineLocation: PropTypes.object,
  setAge: PropTypes.func,
  setCategory: PropTypes.func,
  setDay: PropTypes.func,
  setGroupType: PropTypes.func,
  setKeywords: PropTypes.func,
  setKidsWelcome: PropTypes.func,
  setMeetOnline: PropTypes.func,
};

export default connect(mapStateToProps, {
  fetchLifegroupData,
  removeCampus,
  resetChurchOnlineLocation,
  resetDays,
  resetOtherCategories,
  setAge,
  setCampus,
  setCategory,
  setChurchOnlineLocation,
  setDay,
  setGroupType,
  setKeywords,
  setKidsWelcome,
  setMeetOnline,
  toggleCategoryCheckboxGroup,
  toggleDayCheckboxGroup,
  toggleGroupTypeCheckbox,
  toggleSearchTray,
})(SearchContainer);
