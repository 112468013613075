/* eslint-disable no-shadow */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CampusCheckbox from '../CampusCheckbox';
import MetroCheckbox from '../MetroCheckbox';
import { fetchLifegroupData, removeCampus, setCampus } from '../../actions';
import './MetroGroup.scss';

function MetroGroup({
  fetchLifegroupData,
  filteredList,
  isAllChecked,
  locations,
  name,
  removeCampus,
  setCampus,
  setIsAllChecked,
}) {
  const [isMetroChecked, setIsMetroChecked] = React.useState(true);
  const [isPartial, setIsPartial] = React.useState(false);

  const metroSlugs = locations[1].map((campus) => campus.slug);
  const containsAll = metroSlugs.every((slug) => {
    return filteredList?.includes(slug);
  });
  const containsSome = metroSlugs.some((slug) => {
    return filteredList?.includes(slug);
  });

  function handleMetroClick() {
    if (!isAllChecked) {
      // If only some locations are checked, then...
      if (isMetroChecked) {
        // If fully checked, uncheck everything in group
        setIsMetroChecked(false);
        setIsPartial(false);
        metroSlugs.forEach((slug) => {
          removeCampus(slug);
        });
      } else {
        // If partially checked or unchecked, check everything in group.
        setIsMetroChecked(true);
        setIsPartial(false);
        metroSlugs.forEach((slug) => {
          if (!filteredList?.includes(slug)) {
            setCampus(slug);
          }
        });
      }
    } else {
      // If all locations are checked, then...
      metroSlugs.forEach((slug) => {
        if (!filteredList?.includes(slug)) {
          setCampus(slug);
        }
      });
      setIsAllChecked(false);
      setIsPartial(false);
      setIsMetroChecked(true);
    }
    fetchLifegroupData();
  }

  React.useEffect(() => {
    if (containsAll) {
      setIsMetroChecked(true);
    } else if (containsSome) {
      setIsMetroChecked(false);
      setIsPartial(true);
    } else {
      setIsMetroChecked(false);
      setIsPartial(false);
    }
  }, [containsAll, containsSome]);

  React.useEffect(() => {
    if (!isAllChecked && !filteredList.length) {
      setIsPartial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllChecked]);

  return (
    <div className="metro-group" data-testid="lg-metro-group">
      <MetroCheckbox
        handleClick={handleMetroClick}
        isMetroChecked={isMetroChecked || isAllChecked}
        isPartial={isPartial}
        key={name}
        name={locations[0]}
      />
      {locations[1]
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        })
        .map((campus) => (
          <CampusCheckbox
            isAllChecked={isAllChecked}
            isMetroChecked={isMetroChecked}
            key={campus.slug}
            name={campus.name}
            setIsAllChecked={setIsAllChecked}
            setIsMetroChecked={setIsMetroChecked}
            setIsPartial={setIsPartial}
            slug={campus.slug}
          />
        ))}
    </div>
  );
}

function mapStateToProps({ core }) {
  return {
    campusList: core.campusList,
    filteredList: core.filteredList,
  };
}

MetroGroup.propTypes = {
  isAllChecked: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  setIsAllChecked: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchLifegroupData,
  removeCampus,
  setCampus,
})(MetroGroup);
