const config = {
  development: {
    lgDomain: 'https://my-staging.life.church',
  },
  production: {
    lgDomain: 'https://my.life.church',
  },
  staging: {
    lgDomain: 'https://my-staging.life.church',
  },
  test: {
    lgDomain: 'https://my-testing.life.church',
  },
};

export default config[process.env.NODE_ENV || 'development'];
