/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLifegroupData, removeCampus, resetCampuses } from '../../actions';
import { callSegmentTrack, getUser } from '../../helper';
import { ACTIONS, EVENTS } from '../../types';
import './AllLocationsCheckbox.scss';

function AllLocationsCheckbox({
  fetchLifegroupData,
  filteredList,
  isChecked,
  name,
  removeCampus,
  resetCampuses,
  setIsChecked,
}) {
  function handleClick() {
    const user = getUser();
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: isChecked ? ACTIONS.toggledOff : ACTIONS.toggledOn,
        component: 'Locations Container', // Note: This component is used within the larger LocationsContainer component.
        component_url: null,
        label: isChecked ? 'Deselect All' : 'Select All',
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (isChecked) {
      removeCampus('all');
      setIsChecked(false);
      fetchLifegroupData();
    } else {
      resetCampuses();
      setIsChecked(true);
      fetchLifegroupData();
    }
  }

  React.useEffect(() => {
    if (filteredList?.length > 1 && filteredList?.includes('all')) {
      removeCampus('all');
      setIsChecked(false);
      fetchLifegroupData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList]);

  let classCheckedValue = '';
  if (isChecked) {
    classCheckedValue = 'campus-active';
  } else if (filteredList.length !== 0 && !filteredList?.includes('all')) {
    classCheckedValue = 'partial-check';
  }

  return (
    <div
      className="campus-checkbox all-checkbox"
      data-testid="lg-all-locations-checkbox"
      onClick={handleClick}
    >
      <label className={`lg-location-checkbox`} htmlFor={name}>
        {isChecked ? 'Deselect All' : 'Select All'}
      </label>
      <span className={`checkbox ${classCheckedValue}`} />
    </div>
  );
}

function mapStateToProps({ core }) {
  return {
    filteredList: core.filteredList,
  };
}

AllLocationsCheckbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  setIsChecked: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchLifegroupData,
  removeCampus,
  resetCampuses,
})(AllLocationsCheckbox);
